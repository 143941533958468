<template>
	<div style="position: relative">
		<v-layout wrap>
			<v-flex xs12 md6>
				<v-layout wrap>
					<v-flex xs12>
						<InformacoesPacienteResponsavelEndereco :paciente="assinatura.paciente" :responsaveis="assinatura.paciente.responsaveis" :endereco="assinatura.endereco" :assinatura="assinatura" />
					</v-flex>
					<v-flex xs12>
						<InfoResponsavel
							:responsaveis="assinatura.paciente.responsaveis"
							:pacienteId="assinatura.paciente.id"
							:restHomeId="restHomeId" />
					</v-flex>
					<v-flex xs12>
						<Observacao :assinatura="assinatura" :disabled="!visao_colaborador" @observationUpdated="fetchAssinatura" />
					</v-flex>

					<v-flex xs6>
						<Alergias :paciente="assinatura.paciente" />
					</v-flex>

					<v-flex xs6>
						<Tags :paciente="assinatura.paciente" />
					</v-flex>

					<v-flex xs12>
						<HistoricoBox :assinatura="assinatura" />
					</v-flex>

					<v-flex xs12 v-if="assinatura.receitas">
						<Receitas :receitas="assinatura.receitas" @receita-apagada="onReceitaApagada" />
					</v-flex>

					<v-flex xs6>
						<v-card>
							<v-card-title class="pb-0">
								<v-avatar size="38" class="mr-3">
									<v-icon color="white" small>fas fa-funnel-dollar</v-icon>
								</v-avatar>
								<h3>Serviços Farmaceuticos</h3>
							</v-card-title>
							<v-card-text>
								<v-text-field v-model="assinatura.valor_servico" v-money placeholder="Desconto na assinatura"
									:disabled="$store.getters.user.permissao !== $permissoes.Administrador &&
										$store.getters.user.permissao !== $permissoes.Financeiro &&
										$store.getters.user.permissao !== $permissoes.RT"
									hide-details @change="$root.$emit('ALTEROU', true);" />
							</v-card-text>
						</v-card>
					</v-flex>
					<v-flex xs6>
						<v-card>
							<v-card-title class="pb-0">
								<v-avatar size="38" class="mr-3">
									<v-icon color="white" small>fas fa-funnel-dollar</v-icon>
								</v-avatar>
								<h3>Cobrança</h3>
							</v-card-title>
							<v-card-text>
								<v-switch :readonly="isExternalPaymentReadonly" v-model="assinatura.cobranca_externa" label="A cobrança desta assinatura é feita externamente" hide-details @change="$root.$emit('ALTEROU', true)" />
							</v-card-text>
						</v-card>
					</v-flex>
					<v-flex xs6>
						<v-card>
							<v-card-title class="pb-0">
								<v-avatar size="38" class="mr-3">
									<v-icon color="white" small>fas fa-funnel-dollar</v-icon>
								</v-avatar>
								<h3>Dias de antecedência para entrega</h3>
							</v-card-title>
							<v-card-text>
								<v-text-field v-model.number="assinatura.dias_antecedencia_entrega" type="number" min="0" label="Dias"
									:hint="hintDiasAntecedenciaEntrega" :persistent-hint="!!hintDiasAntecedenciaEntrega" :hide-details="!hintDiasAntecedenciaEntrega"
									@change="$root.$emit('ALTEROU', true)" />
							</v-card-text>
						</v-card>
					</v-flex>
					<v-flex xs6>
						<v-card>
							<v-card-title class="pb-0">
								<v-avatar size="38" class="mr-3">
									<v-icon color="white" small>fas fa-funnel-dollar</v-icon>
								</v-avatar>
								<h3>Outro</h3>
							</v-card-title>
							<v-card-text>
								<v-switch v-model="assinatura.sem_box" label="Esta assinatura é sem box" hide-details @change="$root.$emit('ALTEROU', true)" />
							</v-card-text>
						</v-card>
					</v-flex>

					<v-flex xs12>
						<v-card>
							<v-card-title class="pb-0">
								<v-avatar size="38" class="mr-3">
									<v-icon color="white">swap_horiz</v-icon>
								</v-avatar>
								<h3>Troca de medicamentos</h3>
							</v-card-title>
							<v-card-text>
								<v-switch
									v-model="assinatura.aceita_troca_medicamento"
									label="Permitir a troca dos medicamentos desta assinatura"
									hide-details />
							</v-card-text>
						</v-card>
					</v-flex>

					<v-flex xs12>
						<HistoricoAssinatura />
					</v-flex>

				</v-layout>
			</v-flex>

			<v-flex xs12 md6>
				<v-layout wrap>
					<v-flex xs12 v-if="assinatura.medicamentos">
						<Medicamentos :medicamentos="assinatura.medicamentos" :disabled="$store.getters.user.permissao !== $permissoes.RT &&
							$store.getters.user.permissao !== $permissoes.Financeiro &&
							$store.getters.user.permissao !== $permissoes.Administrador || !!assinatura.rascunho_status" :estoque="estoque_medicamentos" :assinatura="assinatura" :data_base_pausa="data_base_pausa"
							:medicamentosComAproveitamentoReceita="medicamentosComAproveitamentoReceita" :discountPercent="discountPercent" />
					</v-flex>
					<v-flex xs12 class="text-xs-right" v-if="$store.getters.user.permissao >= $permissoes.RT && showButton">
						<v-btn small flat :color="messagem_dialog_excluir.color"
							@click="onClick">
							{{ messagem_dialog_excluir.btn_acao }}
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-dialog persistent scrollable v-model="modal_cancelar" transition="fade" max-width="380px">
				<transition>
					<DialogCancelarAssinatura v-if="modal_cancelar" :assinatura="assinatura"
						@cancelada="fetchAssinatura" @close="modal_cancelar = false" />
				</transition>
			</v-dialog>

			<v-dialog persistent scrollable v-model="modal_restaurar" transition="fade" max-width="380px">
				<transition>
					<Dialog v-if="modal_restaurar"
						titulo="Restaurar assinatura" btn_acao="Restaurar" :loading="restaurando"
						@acao="restaurarAssinatura" @close="modal_restaurar = false" />
				</transition>
			</v-dialog>
		</v-layout>
	</div>
</template>

<script>
import { isBoxBeforePicking } from '@Util/functions';
import { BoxPresenter } from '@Presenter/box-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import moment from 'moment';
import { RestHomeUtil } from '@Util/rest-home';
import { SubscriptionPresenter } from '@Presenter/v2/subscription-presenter';
import InformacoesPacienteResponsavelEndereco from './informacoes-paciente-responsavel-endereco';
import Medicamentos from './medicamentos';
import Receitas from './receitas';
import Observacao from './observacao';
import Alergias from './alergias';
import Tags from './tags';
import HistoricoBox from './historico-box';
import HistoricoAssinatura from './historico-assinatura';
import DialogCancelarAssinatura from './dialog-cancelar-assinatura.vue';
import InfoResponsavel from '../pacientes/paciente/info-responsavel.vue';

export default {
	name: 'ComponenteAssinatura',
	components: {
		InformacoesPacienteResponsavelEndereco, InfoResponsavel, Medicamentos, Receitas, HistoricoBox, HistoricoAssinatura, Alergias, Observacao, Tags, DialogCancelarAssinatura
	},
	props: {
		assinatura: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		modal_cancelar: false,
		modal_restaurar: false,
		restaurando: false,
		carrega_estoque: false,
		estoque_medicamentos: [],
		medicamentosComAproveitamentoReceita: []
	}),
	computed: {
		discountPercent() {
			if (this.assinatura.paciente.casa_repouso)
				return this.assinatura.paciente.casa_repouso.porcentagem_desconto;

			return 0;
		},
		hintDiasAntecedenciaEntrega() {
			return this.$store.getters['assinatura/hintDiasAntecedenciaEntrega'];
		},
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		},
		deleted() {
			return !!(this.assinatura || {}).deleted_at;
		},
		allowRestore() {
			const deletedAt = (this.assinatura || {}).deleted_at;
			if (!deletedAt)
				return false;

			return !!this.$store.getters.eh_tech_team;
		},
		showButton() {
			if (!this.deleted)
				return true;
			return this.allowRestore;
		},
		messagem_dialog_excluir() {
			return {
				titulo: `${this.deleted ? 'Restaurar' : 'Cancelar'} assinatura`,
				btn_acao: this.deleted ? 'Restaurar assinatura' : 'Cancelar assinatura',
				btn_cancelar: this.deleted ? 'Cancelar' : 'Manter assinatura',
				color: this.deleted ? 'purple' : 'error'
			};
		},
		data_base_pausa() {
			let date;
			// verifica se box ja passou pelo picking
			const box = this.assinatura.boxes[0];
			const antesPicking = box && isBoxBeforePicking(box.status);

			if (antesPicking)
				date = moment(box.inicio, 'YYYY-MM-DD');
			else
				date = moment(this.assinatura.data_proxima_box, 'DD/MM/YYYY');

			return date;
		},
		ultimaBox() {
			return this.assinatura.boxes && this.assinatura.boxes.length ? this.assinatura.boxes[0].id : null;
		},
		restHomeId() {
			return this.assinatura.paciente?.casa_repouso?.id;
		},
		isExternalPaymentReadonly() {
			return RestHomeUtil.isExternalPaymentReadonly({
				type: this.assinatura.paciente.casa_repouso?.tipo_pagamento,
				responsible: this.assinatura.paciente.casa_repouso?.responsavel_pagamento
			});
		}
	},
	created() {
		this.getMedicineStock();
		this.getMedicamentosAproveitamentoReceita();
	},
	methods: {
		onClick() {
			if (this.deleted)
				this.modal_restaurar = true;
			else
				this.modal_cancelar = true;
		},
		fetchAssinatura() {
			this.$store.dispatch('assinatura/FETCH_ASSINATURA', this.assinatura.id);
			this.modal_cancelar = false;
			this.modal_restaurar = false;
		},
		onReceitaApagada(i) {
			this.assinatura.receitas.splice(i, 1);
		},
		apagarBox(id) {
			this.assinatura.receitas = this.assinatura.receitas.filter(receita => receita.box_id === id);
		},
		restaurarAssinatura() {
			this.restaurando = true;
			AssinaturaPresenter.restore(this.assinatura.id)
				.then(() => {
					this.fetchAssinatura();
				})
				.finally(() => {
					this.restaurando = false;
				});
		},
		getMedicineStock() {
			if (this.assinatura) {
				this.carrega_estoque = true;
				PacientePresenter.estoque(this.assinatura.paciente_id, { assinatura_id: this.assinatura.id })
					.then(estoque => {
						this.estoque_medicamentos = estoque;
					})
					.catch(() => {
						this.estoque_medicamentos = [];
					})
					.finally(() => {
						this.carrega_estoque = false;
					});
			}
		},
		getMedicamentosAproveitamentoReceita() {
			// Busca aproveitamento somente quando houver box
			if (this.ultimaBox) {
				BoxPresenter.getAproveitamentoReceita(this.ultimaBox)
					.then(aproveitamentoReceitas => {
						this.medicamentosComAproveitamentoReceita = aproveitamentoReceitas;
					});
			}
		},
		async acceptSwapMedicine(value) {
			try {
				await SubscriptionPresenter.acceptSwapMedicine(this.assinatura.id, value);
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						message: value ? 'Troca de medicamentos permitido com sucesso' : 'Troca de medicamento NÃO permitido com sucesso',
						color: 'success'
					}
				);
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						message: error.message || 'Erro ao atualizar o aceita de troca de medicamentos',
						color: 'error'
					}
				);
			}
		}
	},
	watch: {
		'assinatura.aceita_troca_medicamento': {
			async handler(val, oldVal) {
				if (val !== oldVal)
					await this.acceptSwapMedicine(val);
			},
			deep: true
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

@media screen and (min-width: $break-point-md) {
  .flex-end {
    display: flex;
    justify-content: flex-end;
  }
}

.v-card {
	min-height: 156px;
}
</style>
