import $http from '@Config/axios';

const PREFIX = 'subscriptions';

export class SubscriptionPresenter {
	/**
	 * @param {Number} subscriptionId
	 * @param {Boolean} acceptSwapMedicine
	 * @returns {Promise<void>}
	 */
	static async acceptSwapMedicine(subscriptionId, acceptSwapMedicine) {
		await $http.patch(
			`${PREFIX}/${subscriptionId}/accept-swap-medicines`,
			{
				acceptSwapMedicine: !!acceptSwapMedicine
			}
		);
	}
}
