export const featureFlags = Object.freeze({
	enable_farme_new_production_process: 'enable_farme_new_production_process',
	rastreabilidade_por_foto: 'FARMEBOX_RASTREABILIDADE_POR_FOTO',
	conferencia_digital: 'FARME_BOX_CONFERENCIA_DIGITAL_ENABLED',
	list_orders_in_process: 'FARMEBOX_LIST_ORDERS_IN_PROCESS',
	hide_invoicing_and_picking_process: 'FARMEBOX_HIDE_PICKING_AND_INVOICING',
	picking_with_tag: 'FARMEBOX_PICKING_WITH_TAG',
	picking_with_tag_required: 'FARMEBOX_PICKING_WITH_TAG_REQUIRED',
	enable_envelope_code_on_conference: 'FARMEBOX_ENVELOPE_CODE_ON_CONFERENCE_SCREEN',
	integrality_b2c: 'FARMEBOX_ENABLE_INTEGRALITY_B2C',
	integrality_b2b: 'FARMEBOX_ENABLE_INTEGRALITY_B2B',
	picking_orders_with_tag: 'FARMEBOX_ORDERS_PICKING_WITH_TAG',
	picking_orders_with_tag_required: 'FARMEBOX_ORDERS_PICKING_WITH_TAG_REQUIRED',
	min_date_rule: 'FARMEBOX_MIN_DATE_RULE',
	enable_farme_new_charging_screen: 'FARMEBOX_ENABLE_NEW_CHARGING_SCREEN',
	pontualQuotation: 'FARMEBOX_PONTUAL_QUOTATION',
	change_box_medicines_manual_invoicing: 'FARMEBOX_CHANGE_BOX_MEDICINE_MANUAL_INVOICING',
	change_size_box_cycle: 'FARMEBOX_CHANGE_SIZE_BOX_CYCLE',
	change_box_start_date: 'FARMEBOX_CHANGE_BOX_START_DATE',
	limit_date_to_sent_box_to_picking: 'FARMEBOX_LIMIT_DATE_TO_SEND_BOX_TO_PICKING'
});
