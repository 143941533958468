<template>
	<div class="pa-3">
		<v-container fluid grid-list-xl class="pa-0">
			<v-layout wrap>
				<v-flex xs12 md6>
					<h1>Confirme as informações</h1>
					<p>Reveja se os dados fornecidos estão certos, assim como os medicamentos e seus
						respectivos horários. Se estiver tudo certinho, é só continuar.</p>

				</v-flex>

				<template v-if="!clinica">
					<v-flex xs12 md6 >
						<div class="start-box">
							<h4 class="mb-2">Escolha o dia de início da tomada dos medicamentos:</h4>
							<TextDateField v-model="assinatura.data_proxima_box" label="Data de início"
								placeholder="dd/mm/yyyy" :solo="true" color="green_strong" :validate="{ required: true, data: true }"
								:hideDetails="true" :minData="min_data" class="text-date-field" :flat="true" :disabled="disableNextBoxDate" />
						</div>
					</v-flex>

					<v-flex xs12 md4 v-if="assinatura.v2">
						<v-card style="height: 100%">
							<v-card-title class="pb-0">
								<v-avatar size="48" class="mr-3">
									<v-icon color="white" small>
										local_activity
									</v-icon>
								</v-avatar>
								<h3>ID Jornada Digital</h3>
							</v-card-title>
							<v-card-text>
								<v-text-field
									box
									:disabled="assinatura.v2"
									v-model="assinatura.pedido_id"
									label="Copiar e colocar o ID do pedido *"
									placeholder="Caso não seja da Jornada, digite 'NA'"
									color="purple"
									:rules="getOrderIDRules()"/>
							</v-card-text>
						</v-card>
					</v-flex>

					<v-flex xs12 :class="{'md4': assinatura.v2, 'md6': !assinatura.v2}">
						<v-card style="height: 100%">
							<v-card-title class="pb-0">
								<v-avatar size="48" class="mr-3">
									<v-icon color="white" small>
										money
									</v-icon>
								</v-avatar>
								<h3>Modalidade da assinatura</h3>
							</v-card-title>
							<v-card-text>
								<v-layout wrap align-center class="ml-2">
									<v-radio-group v-model="assinatura.modalidade">
										<v-layout wrap  class="ml-1">
											<v-radio v-if="!ativa_integralidade" label="Pagamento proporcional" :value="subcriptionModalities.PROPORCIONAL" color="green" />
											<v-radio label="Pagamento integral" :value="subcriptionModalities.INTEGRAL" color="green" />
										</v-layout>
									</v-radio-group>
									<v-switch
										v-model="assinatura.cobranca_externa"
										label="A cobrança desta assinatura é feita externamente"
										hide-details
										:readonly="isExternalPaymentReadonly"/>

									<v-switch
										v-model="assinatura.aceita_troca_medicamento"
										label="Permitir que os medicamento da assinatura sejam trocados"
										hide-details/>
								</v-layout>
							</v-card-text>
						</v-card>
					</v-flex>

					<v-flex xs12>
						<v-card>
							<v-card-title class="pb-0">
								<v-avatar size="48" class="mr-3">
									<v-icon color="white" small>
										fas fa-truck
									</v-icon>
								</v-avatar>
								<h3>Informações de envio</h3>
							</v-card-title>
							<v-card-text>
								<h4 class="titulo">
									{{ assinatura.paciente.nome }}
								</h4>
								<p class="mb-0">
									{{ assinatura.endereco.logradouro }}, nº {{ assinatura.endereco.numero }}
								</p>
								<p class="mb-0"><span v-if="assinatura.endereco.complemento">{{
									assinatura.endereco.complemento }}, </span>Bairro {{ assinatura.endereco.bairro
								}}</p>
								<p class="mb-0">{{ assinatura.endereco.cidade }}, {{ assinatura.endereco.estado }} -
									{{ assinatura.endereco.cep }}</p>
								<p v-if="assinatura.endereco.observacao" class="mb-0">Observação: {{ assinatura.endereco.observacao }}</p>
							</v-card-text>
						</v-card>
					</v-flex>
				</template>

				<v-flex xs12>
					<v-card>
						<v-card-title class="pb-0">
							<v-avatar size="48" class="mr-3">
								<v-icon color="white" small>
									fas fa-pills
								</v-icon>
							</v-avatar>
							<h3>Medicamentos</h3>
						</v-card-title>
						<v-card-text>
							<v-layout wrap>
								<v-flex xs12 md4 v-for="(item, index) in assinatura.medicamentos" :key="index">
									<MedicamentoCard :medicamento="item" :medicines_checklist="medicines_checklist"  @check-medicine="$emit('check-medicine', item.id)"
										:medicamentos="assinatura.medicamentos" :mostrar_excluir="!um_item" disabled
										:mostrar_como_integral="assinatura.modalidade === subcriptionModalities.INTEGRAL"/>
								</v-flex>
							</v-layout>
						</v-card-text>
					</v-card>
				</v-flex>

				<v-flex xs12 v-if="assinatura.receitas && assinatura.receitas.length > 0">
					<v-card>
						<v-card-title class="pb-0">
							<v-avatar size="48" class="mr-3">
								<v-icon color="white" small>
									fas fa-pills
								</v-icon>
							</v-avatar>
							<h3>Relação de medicamentos</h3>
						</v-card-title>
						<v-card-text>
							<v-layout wrap>
								<v-flex xs12 md3 v-for="(item, index) in assinatura.receitas" :key="index">
									<div class="img-wrapper">
										<img v-if="item.type !== 'application/pdf'" :src="item.dataBase64" class="miniatura" />
										<v-icon v-else @click="openPdf(item)" size="50" class="pointer pa-4 flex-center"
											color="purple">fas fa-file-pdf</v-icon>
									</div>
								</v-flex>
							</v-layout>
						</v-card-text>
					</v-card>
				</v-flex>

				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-textarea v-model="assinatura.observacao" label="Observação" placeholder="Deseja acrescentar alguma observação?" hide-details />
						</v-card-text>
					</v-card>
				</v-flex>

				<v-flex xs12>
					<v-checkbox v-model="check_concordancia" label="Declaro que comparei e conferi as informações contidas na prescrição médica ou termo de consentimento assinado pelo cliente, seguindo os parâmetros estabelecidos no processo da Far.me" />
				</v-flex>

			</v-layout>
		</v-container>

		<DialogMedicamento :visible="dialog_medicamento" :close.sync="dialog_medicamento"
			:medicamento_selecionado="medicamento_selecionado" :medicamentos="assinatura.medicamentos" />
	</div>
</template>

<script>
import DialogMedicamento from '@Componentes/medicamento/dialog-medicamento';
import MedicamentoCard from '@Componentes/medicamento/medicamento-card';
import moment from 'moment';
import { AssinaturaMixin } from '@Mixins/assinatura';
import { openBuffer, validateOrderIdFormat } from '@Util/functions';
import modalidadesAssinatura from '@Consts/modalidade_assinatura';
import { featureFlags } from '@Consts/feature-flags';
import { RestHomeUtil } from '@Util/rest-home';
import { isEqual } from 'lodash';

export default {
	name: 'ConfirmarAssinatura',
	mixins: [AssinaturaMixin],
	components: { DialogMedicamento, MedicamentoCard },
	props: {
		clinica: {
			type: Boolean,
			default: false
		},
		type: {
			type: String // 'b2c' || b2b
		},
		medicines_checklist: {
			type: Array,
			default: () => []
		},
		ilpi: {
			required: false,
			type: Object,
			default: () => {}
		}
	},
	data: () => ({
		dialog_medicamento: false,
		medicamento_selecionado: null,
		min_data: null,
		check_concordancia: false
	}),
	created() {
		if (!this.assinatura.sem_box)
			this.min_data = moment().add(1, 'days').format('YYYY-MM-DD');
		else
			this.min_data = moment().format('YYYY-MM-DD');
		const enableIntegrality = this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2b);
		if (enableIntegrality)
			this.assinatura.modalidade = this.subcriptionModalities.INTEGRAL;

		this.assinatura.medicamentos = this.assinatura.medicamentos.map(m => ({ ...m, preco_com_desconto: m.preco_personalizado - (m.desconto || 0) }));
	},
	computed: {
		um_item() {
			return this.assinatura.medicamentos.length === 1;
		},
		subcriptionModalities() {
			return modalidadesAssinatura;
		},
		disableNextBoxDate() {
			return this.type === 'b2c' && !!this.assinatura.data_proxima_box;
		},
		ativa_integralidade() {
			if (this.type === 'b2c')
				return this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2c);
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2b);
		},
		isExternalPaymentReadonly() {
			return RestHomeUtil.isExternalPaymentReadonly({
				type: this.ilpi?.tipo_pagamento,
				responsible: this.ilpi?.responsavel_pagamento
			});
		},
		isExternalPaymentAllowed() {
			return RestHomeUtil.isExternalPaymentAllowed({
				type: this.ilpi?.tipo_pagamento,
				responsible: this.ilpi?.responsavel_pagamento
			});
		}
	},
	watch: {
		ilpi: {
			deep: true,
			async handler(val, oldVal) {
				if (!isEqual(val, oldVal))
					this.assinatura.cobranca_externa = this.isExternalPaymentAllowed;
			}
		}
	},
	methods: {
		openPdf(receita) {
			openBuffer(receita.buffer);
		},
		getOrderIDRules() {
			if (!this.assinatura.v2)
				return [];

			return [
				value => (!!value) || 'ID do pedido é obrigatório',
				value => (validateOrderIdFormat(value)) || 'ID do pedido não com formato inválido.'
			];
		}
	}
};
</script>

<style lang="scss" scoped>
.v-card {
  ::v-deep .v-card {
    box-shadow: none !important;
  }
}

.img-wrapper {
  position: relative;

  .miniatura {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.08);
  }
}

.text-date-field {
  max-width: 340px;
  border-radius: 7px !important;
  overflow: hidden;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.start-box {
	float: right;

	@media (max-width:  768px) {
		float: left;
	}
}
</style>
